import { createSelector } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { professionSelectors, ProfessionState } from './profession.entity';
import { selectProfessionState } from './profession.state';

export const selectProfession = createSelector(selectProfessionState, professionSelectors.selectAll);

export const selectProfessionError = createSelector(
	selectProfessionState,
	(state: ProfessionState): ErrorMessage => state.error
);
