import { createFeature, createFeatureSelector } from '@ngrx/store';
import { CityState } from './city.entity';
import { cityReducer } from './city.reducer';

export const CITY_FEATURE_NAME: string = 'cities';
export const selectCityState = createFeatureSelector<CityState>(CITY_FEATURE_NAME);

export const cityFeature = createFeature({
    name: CITY_FEATURE_NAME,
    reducer: cityReducer
})