import { createSelector } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { citySelectors, CityState } from './city.entity';
import { selectCityState } from './city.state';

export const selectCityByPrefix = createSelector(selectCityState, citySelectors.selectAll);

export const selectCityByPrefixError = createSelector(
	selectCityState,
	(state: CityState): ErrorMessage => state.error
);
