<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center desktop-dialog">
	<img id="dialogLogoDN" (click)="onExit()" [src]="infoLogoDn" [alt]="'logo'" />
	<div class="dialog-title">{{ getConsentsTitle() }}</div>
	<button mat-icon-button (click)="onExit()">
		<fa-icon class="dn-icon" [icon]="getCrossIcon()"></fa-icon>
	</button>
</div>
<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center mobile-dialog">
	<button mat-icon-button (click)="onExit()">
		<fa-icon class="dn-icon" [icon]="getChevronLeftIcon()"></fa-icon>
	</button>
	<div class="dialog-title">{{ getConsentsTitle() }}</div>
	<img id="dialogLogoDN" (click)="onExit()" [src]="infoLogoDn" [alt]="'logo'" />
</div>

<hr class="dialog-title-separator" />

<div class="dialog-body-wrapper">
	<p class="consent-body">
		{{ getConsentsBody() }}
	</p>
	<div class="flex-row flex-justify-center">
		<button mat-raised-button color="accent" (click)="onExit()">
			{{ 'dottnet.button.consents.fine' | translate }}
		</button>
	</div>
</div>
